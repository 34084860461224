.app-left-menu {
  width: 100%;
  height: 100%;
  width: 80px;
  background-color: rgba(57, 63, 90, 1);
  .level-item {
    width: 80px;
    height: 80px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    &.active {
      background-color: rgba(42, 47, 71, 1);
      color: #26defd;
    }
    .img {
      width: 28px;
      height: 28px;
    }
    .title {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
