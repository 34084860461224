html,
body {
  width: 100%;
  height: 100%;
}
#appRoot {
  height: 100%;
  min-height: 100%;
  text-size-adjust: 100%;
  background-color: #1d1f3a;
  line-height: 1;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif,
    SourceHanSansCN-Regular, SourceHanSansCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

div {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}
/*控制整个滚动条*/
::-webkit-scrollbar {
  background-color: rgba(22, 25, 69, 0.5);
  width: 4px;
  height: 10px;
  background-clip: padding-box;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
  background-color: rgba(129, 130, 148, 1);
  border-radius: 4px;
}

/*滚动条右下角区域*/
::-webkit-scrollbar-corner {
  background-color: transparent;
}
