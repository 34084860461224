.App {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  color: #c6c6cf;
}

.main {
  flex: 1;
  background-color: #1d1f3a;
  display: flex;
  height: calc(100% - 85px);
}
.mainFull {
  flex: 1;
  background-color: #1d1f3a;
  display: flex;
  height: 100%;
}
.inner_right {
  flex: 0 0 40%;
}

.appWraper {
  flex: 1;
  display: flex;
  width: calc(100% - 80px);
  height: 100%;
  overflow-y: auto;
}
.appWraperMain {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
}
.appWraper > div,
.appWraperMain > div {
  flex: 1;
  width: 100%;
  height: 100%;
}
.footer {
  background-color: #393f5a;
  z-index: 99999;
  padding-left: 100px;
  line-height: 25px;
}
.footer_span {
  margin-right: 20px;
}
