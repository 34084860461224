.dashboard-main {
  display: flex;
  flex-direction: column;
  .flex {
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }
  .title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(175, 178, 189, 1);
    line-height: 24px;
  }
  .left-div {
    margin-right: 15px;
    min-width: 600px;
    flex: auto;
    margin-top: 20px;
    .conventional-div {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .right-div {
    flex: 0 0 430px;
    margin-right: 15px;
    width: 430px;
    margin-top: 20px;
    .line-div {
      width: 431px;
      height: 1px;
      background-color: rgba(29, 31, 58, 1);
    }
    .userCard {
      width: 430px;
      height: 180px;
      background: rgba(222, 224, 255, 0.1);
      box-shadow: 0px 0px 4px 0px rgba(22, 25, 69, 0.5);
      .top-div {
        height: 90px;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        .img-user {
          height: 100%;
        }
        .userName {
          height: 50px;
          justify-content: space-between;
          margin-left: 10px;
          span {
            height: 14px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
          }
        }
        .personal-center {
          cursor: pointer;
          width: 80px;
          height: 34px;
          background: rgba(36, 222, 254, 0.15);
          border-radius: 4px;
          text-align: center;
          line-height: 34px;
          span {
            font-size: 14px;
            color: rgba(35, 221, 253, 1);
          }
        }
        .finance-center {
          cursor: pointer;
          margin-left: 5px;
          width: 80px;
          height: 34px;
          background: #ffc22a;
          border-radius: 4px;
          text-align: center;
          line-height: 34px;
          span {
            font-size: 14px;
            color: #000000;
          }
        }
      }
      .bottom-div {
        justify-content: space-around;
        padding: 20px;
        align-items: center;
        height: 89px;
        .cell-div {
          height: 100%;
          align-items: center;
          justify-content: space-between;

          span:first-child {
            font-size: 18px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
          }
          span:last-child {
            font-size: 12px;
            font-weight: 400;
            color: rgba(175, 178, 189, 1);
          }
        }
      }
    }
    .weChat-div {
      justify-content: space-between;
      margin-top: 20px;
      padding: 20px;
      width: 430px;
      height: 180px;
      background: rgba(222, 224, 255, 0.1);
      box-shadow: 0px 0px 4px 0px rgba(22, 25, 69, 0.5);
      .describe-div {
        justify-content: space-around;
        .describe-top-div {
          justify-content: space-around;
          font-size: 18px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          line-height: 27px;
          img {
            height: 29px;
            width: 34px;
          }
        }
        .describe-bottom-div {
          justify-content: space-around;
          height: 68px;
          p {
            font-size: 14px;
            font-weight: 400;
            color: rgba(198, 198, 207, 1);
          }
        }
      }
    }
  }
}
